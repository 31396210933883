import globe from '../Images/Fill 213.png';
import '../App.css';
const Header = () => {
  return (
    <header>
      <img className="globe" src={globe} alt="globe" />
      <h2>My Future Travels</h2>
    </header>
  );
};

export default Header;
